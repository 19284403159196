import * as VueRouter from 'vue-router'
import Cookies from 'js-cookie'

const routes: VueRouter.RouteRecordRaw[] = [
  { path: '/login', component: () => import('./pages/login.vue') },
  {
    path: '/',
    component: () => import('./components/layout.vue'),
    redirect: '/index',
    children: [
      { path: '/index', component: () => import('./pages/index.vue') },
      { path: '/about', component: () => import('./pages/about.vue') },
      {
        path: '/systemSetting',
        component: () => import('./pages/systemSetting.vue'),
        redirect:'/systemSetting/meetingRoomManage',
        children:[
          { path: '/systemSetting/meetingRoomManage', component: () => import('./components/meetingRoomManage.vue') },
          { path: '/systemSetting/deviceManage', component: () => import('./components/deviceManage.vue') },
        ]
      },
    ],
  },
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes,
})

const whiteList = ['/login']

router.beforeEach((to) => {
  if (!whiteList.includes(to.path) && !Cookies.get('token')) {
    return { path: '/login' }
  }
})

export default router
